.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Main, Footer, Header */
footer{
  min-height: 4rem;
}

/* Background Colors */
.bg-1 {
  background-color: #cbe896;
}
.bg-2 {
  background-color: #a18276;
}
.bg-3 {
  background-color: #E0E0E0;
}
.bg-4 {
  background-color: #fcdfa6 !important;
}


/* Text */
h1, h2, h3, h6, h5 {
  font-family: 'Modak';
  margin-bottom: 0 !important;
  color: #8CAD8C
}
h6{
  color: #282c34;
}


p{
  margin-bottom: 0;
  color:#282c34;
}

p, a{
  font-family: 'Fira Sans';
}

a {
  text-decoration: none !important;
}
.text-color-1{
  color: #8CAD8C
}
.text-color-2{
  color: #a18276
}
.text-color-3{
  color: #E0E0E0
}

/* Heading Text - Decorations */
.headingDecorationOne {
  background: #E0E0E0;
  font-size: 18px;
}



/* Lists */
ul, ol {
  list-style: none;
  padding: 0;
  margin:  0;
}

/* Font Styles */
.fira-sans-semibold {
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.fira-sans-thin-italic {
  font-family: "Fira Sans", sans-serif;
  font-weight: 200;
  font-style: italic;
}
.fira-sans-thin-italic-bold {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

/* Row Styles */
.row{
  --bs-gutter-x: 0;
}

/* FORMS */
legend{
  font-size: 1.5rem;
}
label {
  font-size: 1rem;
}
input{
  font-size: .85rem !important;
  padding: .25rem .75rem !important;
}

/* Borders */
.border-2 {
  border-color: #fcdfa6 !important
}
.border-2-alt {
  border-color: #f4b886 !important
}
.border-color-main {
  border-color: #fcdfa6 !important;
}

/* Icons */
.icon-color-main {
  color: #fcdfa6
}

/* Button */
.button {
  background: #a18276;
  border: #a18276;
  color: white;
  font-family: "Modak";
}
.btn-sm{
  width: 20% !important;
}

/* button:hover {
  border: 1.75px solid #fcdfa6 !important;
  transform: scale(1.1);
} */

/* Hover& Focus Effects */

.onFocus {
  border: 1.75px solid #fcdfa6 !important;
  transform: scale(1.1);
}

.iconHover:hover{
  border: none !important;
  transform: scale(1.05) !important;
}

.onHover:hover {
  transform: scale(1.05);
}

.icons-sm {
  width: 20% !important;
}
.w-55{
  width: 55% !important;
}
.mobileAppIconFooter {
  display: none !important;
}
.footer-xl-logo {
  width: 100px !important;
  max-width: 120px;
}

.aboutBio {
  line-height: 1.85rem;
  text-align: center !important;
}

.aboutBio b {
  background: #8CAD8C;
  padding: 0.15rem;
  color: white;
}

.aboutBio b:nth-child(2),
.aboutBio b:nth-child(3),
.aboutBio b:nth-child(6) {
  background: #a18276;
}

.aboutBio b:nth-child(4),
.aboutBio b:nth-child(7) {
  background: #E0E0E0;
}

/* Media Queries */
@media (min-width: 390px) and (max-width:600px) and (min-height: 675px){
  label {
    font-size: 1.35rem !important;
  }
  legend {
    font-size: 2rem !important;
  }
  input {
    padding: .5rem .75rem !important;
    font-size: 1.15rem !important;
  }
  header button {
    padding: .75rem !important;
  }
  footer a{
    font-size: 14px;
  }
  p {
    font-size: 1rem !important;
  }
  .pwdStrength {
    margin-top: .5rem !important;
  }
  button[type="submit"] {
    padding: .65rem !important;
    margin-bottom: .5rem;
  }
  .pwdStrength h6 {
    font-size: 1.5rem !important;
  }
  .pwdStrength p {
    margin: .75rem 0;
  }
  .fa-brands {
    font-size: 2.5rem !important;
  }
  form p:last-of-type{
    font-size: 1.25rem !important;
  }
  .form-control:last-of-type {
    margin-bottom: .5rem !important;
  }
}

/* Animations */
.fa-bounce, .fa-beat-fade, .fa-flip, .fa-beat {
  animation-iteration-count: 1 !important;
}
.delay-1 {
  animation-delay: .45s !important;
}
.delay-2 {
  animation-delay: .65s !important;
}
.delay-3 {
  animation-delay: .85s !important;
}

/* Media Queries */
@media (min-width: 768px) and (max-width:1025px) and (min-height: 800px) {
  form{
    max-width: 70% !important;
  }
  label{
    font-size: 1.65rem !important;
  }
  legend {
    font-size: 2.25rem !important;
  }
  input{
    padding: .5rem .75rem !important;
    font-size: 1.35rem !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .h-md{
    height: fit-content !important;
  }
  .footer-md-h{
    height: 8% !important;
  }
  .fs-md-5 {
    font-size: 20px !important;
  }
  .fs-md-6 {
    font-size: 20px !important;
  }
  .form-control:last-of-type {
    margin-bottom: .5rem !important;
  }
  header button {
    padding: .75rem !important;
  }
  button[type="submit"] {
    padding: .65rem !important;
    font-size: 1.25rem !important;
    margin-bottom: .5rem;
  }
  .fa-brands {
    font-size: 2.5rem !important;
  }
  .pwdStrength {
    margin: .75rem 0 !important;
  }
  .pwdStrength h6 {
    font-size: 1.75rem !important;
  }
  .pwdStrength p {
    font-size: 1.25rem !important;
    margin: .75rem 0;
  }
  .border-md-end{
    border-right: 1px solid;
  }
  .border-md-bottom{
    border-bottom: 0 !important;
  }
  footer p {
    font-size: 1rem !important;
  }
  .icons-md{
    width: 25% !important;
  }
  .btn-md{
    width: 11% !important;
  }
  .footer-xl-logo {
    width: 100px !important;
    max-width: 120px;
  }
  .w-25{
    width: 35% !important;
  }
  .mobileAppIconHome {
    display: none !important;
  }
  .mobileAppIconFooter {
    display: flex !important;
  }
}

/* Nest Hubs */
@media (width: 1024px) and (height: 600px) {
  .col-lg-10 {
    width: 70% !important;
  }
  .col-lg-9 {
    width: 60% !important;
  }
  .col-lg-8 {
    width: 55% !important;
  }
  .col-lg-4 {
    width: 27% !important;
  }
  footer {
    height: 5% !important;
  }
  .border-md-0{
    border: 0 !important;
  }
  .fs-lg-heading {
    font-size: 18px !important;
  }
  .fs-2 {
    font-size: 25px !important;
  }
  .btn-sm {
    width: 8% !important;
  }
  .border-md-bottom {
    border: 0 !important;
  }
  .border-md-end {
    border-right: 1px solid !important;
  }
  h4{
    font-size: 20px !important;
  }
  .mobileAppIconHome {
    display: none !important;
  }

  .mobileAppIconFooter {
    display: flex !important;
  }
}

/* Surface Duo */
@media (width: 540px) and (height: 720px) {
  .col-lg-10 {
    width: 55% !important;
    margin: 0 auto !important;
  }
  .col-10 {
    flex: 0 0 auto;
    width: 60.333333% !important;
  }
  .col-lg-9 {
    width: 45% !important;
  }
  .col-lg-8 {
    width: 50% !important;
  }
  .col-lg-4 {
    width: 25% !important;
  }
  footer {
    height: 5% !important;
  }
  .border-md-0{
    border: 0 !important;
  }
  .fs-lg-heading {
    font-size: 18px !important;
  }
  .fs-2 {
    font-size: 25px !important;
  }
  .btn-sm {
    width: 15% !important;
  }
  .border-md-bottom {
    border: 0 !important;
  }
  .border-md-end {
    border-right: 1px solid !important;
  }
  h4{
    font-size: 20px !important;
  }
  .icons-sm {
    width: 13% !important;
  }
  .w-25{
    width: 13% !important;
  }
  .pt-1{
    margin-bottom: 4rem !important;
  }
  .w-55 {
    width: 45% !important;
    border-right:  0 !important;
    border-bottom: 1px solid !important;
  }
}

/* Nest Hubs Max*/
@media (width: 1280px) and (height: 800px) {
  .col-lg-10 {
    width: 70% !important;
  }
  footer {
    min-height: 15% !important;
  }
  .mobileAppIconHome {
    display: none !important;
  }

  .mobileAppIconFooter {
    display: flex !important;
  }
}

@media (min-width: 1024px) and (min-height: 800px) {
  .fs-lg{
    font-size: 17px !important;
  }
  .fs-lg-heading{
    font-size: 25px !important;
  }
  .fs-lg-sub-heading{
    font-size: 19px !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  footer {
    height: 10% !important;
  }
  .footer-xl-logo {
    width: 110px !important;
    max-width: 120px;
  }
  .h-md-90{
    height: 90% !important;
  }
  .h-md-75{
    height: 75% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-25{
    width: 25% !important;
  }
  .border-md-bottom {
    border-bottom: 0 !important;
  }
  .border-md-end {
    border-right: 1px solid;
  }
  .btn-lg {
    width: 8% !important;
  }
  .aboutBio {
    line-height: 2.15rem !important;
  }
  .mobileAppIconHome {
    display: none !important;
  }
  .mobileAppIconFooter {
    display: flex !important;
  }
}

@media (min-width: 1920px) and (min-height: 800px) {
  form{
    max-width: 45% !important;
  }
  label{
    font-size: 1.8rem !important;
  }
  legend {
    font-size: 2.75rem !important;
  }
  input{
    padding: .75rem .75rem !important;
    font-size: 1.5rem !important;
  }
  .form-control:last-of-type {
    margin-bottom: .75rem !important;
  }
  header button {
    padding: .75rem !important;
    font-size: 1.25rem !important;
  }
  button[type="submit"] {
    padding: .65rem !important;
    font-size: 1.5rem !important;
    margin-bottom: .5rem;
  }
  .fa-brands {
    font-size: 2.75rem !important;
  }
  .pwdStrength {
    margin: .75rem 0 !important;
  }
  .pwdStrength h6 {
    font-size: 1.75rem !important;
  }
  .pwdStrength p {
    font-size: 1.25rem !important;
    margin: .75rem 0;
  }
  footer{
    height: 18% !important;
    padding-top: .75rem !important;
  }
  footer p {
    font-size: 1.25rem !important;
  }
  .icons-lg {
    width: 20% !important;
  }
  .fs-xl {
    font-size: 18px !important;
  }
  .fs-xl-heading {
    font-size: 30px !important;
  }
  .aboutBio {
    font-size: 22px !important;
      line-height: 2.5rem;
  }
  h6 {
    margin: 0 .75rem !important;
  }
  .w-25{
    width: 20% !important;
  }
  .homeIconSocials{
    width: 25% !important;
  }
  .projectNavIcon {
    width: 5.5rem !important;
    margin-right: 1.75rem !important;
  }
  h4 {
    font-size: 30px !important;
  }
  .projectTitle {
    font-size: 40px !important;
  }
  .iconSocials {
    width: 3.5rem !important;
  }
  .btn-xl{
    width: 6% !important;
  }
  .aboutMoreIcons {
    width: 2.5rem !important;
  }
  .footer-xl-logo{
    width: 125px !important;
    max-width: 150px;
  }
  .mobileAppIconHome {
    display: none !important;
  }

  .mobileAppIconFooter {
    display: flex !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
